<template>
  <div class="about">
    <keep-alive>
      <Hero />
    </keep-alive>
    <Features />
    <Brief />
    <!-- <Reasons /> -->
    <!-- <Contact /> -->
  </div>
</template>

<script>
import Hero from "./components/Hero.vue";
import Brief from "./components/Brief.vue";
import Features from "./components/Features.vue";
// import Reasons from "./components/Reasons.vue";
// import Contact from "@/views/Contact.vue";

export default {
  name: "About",
  components: {
    Hero,
    Brief,
    Features,
    // Reasons,
    // Contact,
  },
};
</script>

<style lang="scss" scoped></style>
