<template>
  <v-parallax
    id="hero-parallax"
    class="px-0"
    height="800"
    :class="{
      'mt-10': $vuetify.breakpoint.xsOnly,
    }"
    :src="require('@/assets/wearelocals_hero.jpg')"
  >
    <div id="hero">
      <v-container class="content fill-height white--text">
        <v-row no-gutters>
          <v-col class="hero-block py-8 pr-8" cols="12" md="8">
            <div
              class="mb-4 font-weight-bold primary--text"
              :class="{
                'display-2': $vuetify.breakpoint.smAndUp,
                'display-1': $vuetify.breakpoint.xsOnly,
              }"
            >
              <span class="d-inline-blockpy-1">
                Your local shops
              </span>
              <br />
              <span class="d-inline-blockpy-1">
                all selling online
              </span>
              <br />
              <span class="d-inline-blockpy-1">
                in one place
              </span>
            </div>
            <div class="mb-10 font-weight-regular">
              <span class="hero-subtitle black--text">
                Designed specifically for BIDS and Councils in mind. Join us on
                our journey to support local, shop local.
              </span>
            </div>
            <v-btn
              target="_blank"
              href="https://wearebs3.co.uk"
              class="mr-8 mb-4"
              large
              color="primary"
              >See us in action</v-btn
            >
            <v-btn
              class="mr-8 mb-4"
              large
              color="primary"
              @click="scroll_to_features()"
              >Discover our features</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-parallax>
</template>

<script>
export default {
  name: "Hero",
  methods: {
    scroll_to_features() {
      let features_pos = document.getElementById("features").offsetTop;
      let navigation_height = parseInt(
        document.getElementById("navigation").style.height
      );

      window.scrollTo({
        top: features_pos - navigation_height,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    document.getElementById("hero-parallax").style.height = "100vh";
  },
};
</script>

<style lang="scss" scoped>
#hero {
  color: #ffffff;
  margin: 0 -4 * $spacer;
  position: relative;
  padding: 4 * $spacer 0;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
  }

  .hero-block {
    position: relative;
    z-index: 2;
    &:after {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: white;
      margin-left: -9999px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        margin-right: -9999px;
      }
    }
  }
}
</style>
