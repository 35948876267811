<template>
  <div id="brief" class="py-12 primary white--text">
    <v-container class="content">
      <v-row class="py-10">
        <v-col cols="12">
          <p class="mx-2 mb-12">
            <span class="title py-2 my-3 white--text">
              We are not aware of any other Ecommerce platform that does this.
              With the acceleration to online sales now is the time more than
              ever for high streets to get everyone online in one place.
            </span>
          </p>
          <p class="mx-2">
            We have priced our platform as low as possible to enable ongoing
            re-investment in research and development to ensure the platform
            becomes an outstanding tech application for independent businesses
            to use their local advantage and make true the formula:
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <p class="text-center my-4 display-2 my-4 font-weight-black">
            Local bricks
            <v-icon color="white" large>mdi-plus</v-icon>
            clicks
            <v-icon color="white" large>mdi-greater-than</v-icon>
            Amazon
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Brief",
};
</script>

<style lang="scss" scoped>
.blue-text {
  background-color: rgba(0, 56, 101);
  box-shadow: 10px 0 0 rgba(0, 56, 101), -10px 0 0 rgba(0, 56, 101);
}
</style>
