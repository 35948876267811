<template>
  <div id="features" class="py-12">
    <v-container class="content">
      <h2 class="display-2 mb-10 font-weight-bold primary--text">
        What #WEARELOCALS offers
      </h2>
      <div v-for="item in features" :key="item.title">
        <div class="d-flex align-center mb-5 mt-10">
          <v-btn fab elevation="0" color="primary" class="disable-events mr-4"
            ><v-icon color="white">mdi-check</v-icon></v-btn
          >
          <span class="font-weight-bold primary--text headline"
            >{{ item.title }}
          </span>
        </div>
        <div class="features-screenshots">
          <vue-preview :slides="item.screenshots" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Features",
  data() {
    return {
      features: [
        {
          title:
            "Your own branded website completely set up on a custom domain",
          screenshots: [
            {
              src: require("@/assets/screenshots/shops_landing_page.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_shops_landing_page.jpg"),
              alt: "#wearebs3 Landing Page",
              title: "#wearebs3 Landing Page",
              w: 1850,
              h: 939,
            },
            {
              src: require("@/assets/screenshots/shops_mins_landing_page.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_shops_mins_landing_page.jpg"),
              alt: "#wearebs3 Landing Page",
              title: "#wearebs3 Landing Page",
              w: 1850,
              h: 939,
            },
          ],
        },
        {
          title:
            "Slick front end to showcase shops and reinforce local sense of place",
          screenshots: [
            {
              src: require("@/assets/screenshots/shops_browser.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_shops_browser.jpg"),
              alt: "Shops Browser",
              title: "Shops Browser",
              w: 1850,
              h: 939,
            },
            {
              src: require("@/assets/screenshots/shops_shop_front.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_shops_shop_front.jpg"),
              alt: "Shop Front",
              title: "Shops Front",
              w: 1850,
              h: 939,
            },
          ],
        },
        {
          title:
            "Comprehensive back end app including unlimited products management and order fulfilment tools",
          screenshots: [
            {
              src: require("@/assets/screenshots/settings_general.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_settings_general.jpg"),
              alt: "Admin Panel Settings",
              title: "Admin Panel Settings",
              w: 1850,
              h: 939,
            },
            {
              src: require("@/assets/screenshots/settings_orders.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_settings_orders.jpg"),
              alt: "Admin Panel Order Settings",
              title: "Admin Panel Order Settings",
              w: 1850,
              h: 939,
            },
            {
              src: require("@/assets/screenshots/settings_products.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_settings_products.jpg"),
              alt: "Admin Panel Products Settings",
              title: "Admin Panel Products Settings",
              w: 1850,
              h: 939,
            },
          ],
        },
        {
          title:
            "Create optimised delivery routes or even pool delivery resources to fulfill orders ",
          screenshots: [
            {
              src: require("@/assets/screenshots/settings_deliveries.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_settings_deliveries.jpg"),
              alt: "Admin Panel Planned Deliveries",
              title: "Admin Panel Planned Deliveries",
              w: 1850,
              h: 939,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#features {
  padding: $section-padding 0;
}

.features-screenshots {
  margin: 0 75px;
}

.my-gallery {
  display: flex;
  flex-wrap: wrap;

  figure {
    img {
      width: 220px;
    }
  }
  > * {
    margin: 0 10px 20px;
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }
  }
}
</style>
